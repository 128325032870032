<ng-container *ngIf="comment; else addComment">
  <ng-container
    [ngTemplateOutlet]="commentContent"
    [ngTemplateOutletContext]="{ comment }"
  >
  </ng-container>
  @for (comment of comment.replies; track $index) {
    <ng-container
      [ngTemplateOutlet]="commentContent"
      [ngTemplateOutletContext]="{ comment }"
    >
    </ng-container>
  }
  <div *ngIf="allowReplies">
    <ng-container *ngIf="!isAddingReply && !issueResolved; else addComment">
      <mat-form-field (click)="enableAddReply()">
        <input matInput placeholder="Reply..." />
      </mat-form-field>
    </ng-container>
  </div>
</ng-container>

<ng-template #commentContent let-comment="comment">
  <div
    class="comment"
    [class.self]="comment.currentUser"
    [class.reply]="comment.parentCommentId"
  >
    <div
      class="comment-status"
      [class.active]="isSaving && currentComment === comment"
    >
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="comment-header">
      <div class="comment-user-details">
        <div class="author">
          <h4 class="mat-h4">{{ comment.author }}</h4>
          <div class="role">
            <mat-icon>person</mat-icon>
            <span>{{ comment.role }}</span>
          </div>
        </div>
        <div class="date">
          <span>{{
            comment.addedDate + '+0000' | date: 'M/dd/yyyy h:mm:ss a'
          }}</span>
        </div>
      </div>
      <ng-container
        *ngIf="!readOnly && comment.currentUser"
        [ngTemplateOutlet]="commentMenu"
        [ngTemplateOutletContext]="{ comment }"
      >
      </ng-container>
    </div>
    <div class="comment-body">
      <span *ngIf="!isEditing || currentComment !== comment">
        {{ comment.comment }}
      </span>
      <ng-container
        *ngIf="isEditing && currentComment === comment"
        [ngTemplateOutlet]="commentFormTemplate"
        [ngTemplateOutletContext]="{ comment }"
      >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #addComment>
  <div class="comment-status" [class.active]="isSaving">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <ng-container
    *ngIf="!isEditing && !issueResolved"
    [ngTemplateOutlet]="commentFormTemplate"
  ></ng-container>
</ng-template>

<ng-template #commentFormTemplate let-comment="comment">
  <form
    [formGroup]="commentForm"
    (ngSubmit)="submitComment()"
    [cdkTrapFocus]="isEditing"
  >
    <input type="hidden" formControlName="id" />
    <fieldset>
      <mat-form-field class="fill-white">
        <textarea
          #textAreaComment
          matInput
          formControlName="comment"
          cdkFocusInitial
          title="Comment"
        ></textarea>
      </mat-form-field>
    </fieldset>
    <div class="form-actions">
      <button
        mat-stroked-button
        color="primary"
        type="submit"
        [disabled]="isSaving || !textAreaComment.value.length"
      >
        Save Comment
      </button>
      <button
        mat-stroked-button
        type="button"
        *ngIf="comment?.id"
        (click)="disableEditComment()"
      >
        Cancel
      </button>
      <button
        mat-stroked-button
        type="button"
        *ngIf="isAddingReply"
        (click)="isAddingReply = false"
      >
        Cancel
      </button>
    </div>
  </form>
</ng-template>

<ng-template #commentMenu let-comment="comment">
  <button mat-icon-button [matMenuTriggerFor]="matMenu">
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #matMenu="matMenu">
    <button mat-menu-item (click)="enableEditComment(comment)">
      <mat-icon>edit</mat-icon><span>Edit</span>
    </button>
    <button
      mat-menu-item
      class="delete-button"
      (click)="deleteComment(comment)"
    >
      <mat-icon>delete</mat-icon><span>Delete</span>
    </button>
  </mat-menu>
</ng-template>
