export enum SpecialFacilityAccess {
  WheelchairAccessible = 'Wheelchair Accessible',
  SameDayAppointment = 'Same Day Appointment/Walk-Ins Accepted',
  ExtendedWeekendHours = 'Extended or Weekend Hours',
  TeenClinicHours = 'Teen Clinic/Hours Available',
}

export enum CldbHistoryOfChanges {
  OrganizationActivated = 'Organization Activated',
  OrganizationCreated = 'Organization Created',
  OrganizationDeactivated = 'Organization Deactivated',
  OrganizationEdited = 'Organization/Location',
  OrganizationHoursEdited = 'Hours of Operation',
  OrganizationPublished = 'Organization Published',
  OrganizationServicesEdited = 'Services',
  OrganizationUnpublished = 'Organization Unpublished',
}
