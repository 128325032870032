import { State as RootState } from '@app/store/app-state';
import {
  ApiError,
  CldbHistoryOfChangesEntry,
  ClinicServices,
  Site,
  SiteDetails,
  UserGrantSelection,
} from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { siteFeatureKey } from './reducer';
import { State } from './state';

const associatedGrant = (state: State): UserGrantSelection =>
  state.associatedGrant;
const associatedGrantError = (state: State): ApiError =>
  state.associatedGrantError;
const associatedGrantLoading = (state: State): boolean =>
  state.associatedGrantLoading;
const createSiteError = (state: State): ApiError => state.createSiteError;
const createSiteLoading = (state: State): boolean => state.createSiteLoading;
const currentSiteId = (state: State): string => state.currentSiteId;
const clinicServices = (state: State): ClinicServices[] => state.clinicServices;
const getClinicServicesError = (state: State): ApiError =>
  state.getClinicServicesError;
const getClinicServicesLoading = (state: State): boolean =>
  state.getClinicServicesLoading;
const getHistoryOfChanges = (state: State): CldbHistoryOfChangesEntry[] =>
  state.historyOfChanges;
const getHistoryOfChangesError = (state: State): ApiError =>
  state.historyOfChangesError;
const getHistoryOfChangesLoading = (state: State): boolean =>
  state.historyOfChangesLoading;

const getHistoryOfChangeDetails = (state: State) =>
  state.historyOfChangeDetails;
const getHistoryOfChangeDetailsError = (state: State): ApiError =>
  state.historyOfChangeDetailsError;
const getHistoryOfChangeDetailsLoading = (state: State): boolean =>
  state.historyOfChangeDetailsLoading;

const getSites = (state: State): Site[] => state.sites;
const getSitesError = (state: State): ApiError => state.sitesError;
const getSitesLoading = (state: State): boolean => state.sitesLoading;

const getSiteDetails = (state: State): SiteDetails => state.siteDetails;
const getSiteDetailsError = (state: State): ApiError => state.siteDetailsError;
const getSiteDetailsLoading = (state: State): boolean =>
  state.siteDetailsLoading;

const noaFile = (state: State): File => state.noaFile;
const publishSiteError = (state: State): ApiError => state.publishSiteError;
const publishSiteLoading = (state: State): boolean => state.publishSiteLoading;
const removeSiteError = (state: State): ApiError => state.removeSiteError;
const removeSiteLoading = (state: State): boolean => state.removeSiteLoading;
const updateServicesError = (state: State): ApiError =>
  state.updateServicesError;
const updateServicesLoading = (state: State): boolean =>
  state.updateServicesLoading;
const updateSiteError = (state: State): ApiError => state.updateSiteError;
const updateSiteHoursError = (state: State): ApiError =>
  state.updateSiteHoursError;
const updateSiteHoursLoading = (state: State): boolean =>
  state.updateSiteHoursLoading;
const updateSiteLoading = (state: State): boolean => state.updateSiteLoading;

export const selectSitesState = createFeatureSelector<RootState, State>(
  siteFeatureKey,
);

export const selectAssociatedGrant = createSelector(
  selectSitesState,
  associatedGrant,
);
export const selectAssociatedGrantError = createSelector(
  selectSitesState,
  associatedGrantError,
);
export const selectAssociatedGrantLoading = createSelector(
  selectSitesState,
  associatedGrantLoading,
);

export const selectCreateSiteError = createSelector(
  selectSitesState,
  createSiteError,
);
export const selectCreateSiteLoading = createSelector(
  selectSitesState,
  createSiteLoading,
);

export const selectCurrentSiteId = createSelector(
  selectSitesState,
  currentSiteId,
);

export const selectNoaFile = createSelector(selectSitesState, noaFile);

export const selectClinicServices = createSelector(
  selectSitesState,
  clinicServices,
);
export const selectGetClinicServicesError = createSelector(
  selectSitesState,
  getClinicServicesError,
);
export const selectGetClinicServicesLoading = createSelector(
  selectSitesState,
  getClinicServicesLoading,
);

export const selectHistoryOfChanges = createSelector(
  selectSitesState,
  getHistoryOfChanges,
);
export const selectHistoryOfChangesError = createSelector(
  selectSitesState,
  getHistoryOfChangesError,
);
export const selectHistoryOfChangesLoading = createSelector(
  selectSitesState,
  getHistoryOfChangesLoading,
);

export const selectHistoryOfChangeDetails = createSelector(
  selectSitesState,
  getHistoryOfChangeDetails,
);
export const selectHistoryOfChangeDetailsError = createSelector(
  selectSitesState,
  getHistoryOfChangeDetailsError,
);
export const selectHistoryOfChangeDetailsLoading = createSelector(
  selectSitesState,
  getHistoryOfChangeDetailsLoading,
);

export const selectPublishSiteError = createSelector(
  selectSitesState,
  publishSiteError,
);
export const selectPublishSiteLoading = createSelector(
  selectSitesState,
  publishSiteLoading,
);

export const selectRemoveSiteError = createSelector(
  selectSitesState,
  removeSiteError,
);
export const selectRemoveSiteLoading = createSelector(
  selectSitesState,
  removeSiteLoading,
);

export const selectSites = createSelector(selectSitesState, getSites);
export const selectSitesError = createSelector(selectSitesState, getSitesError);
export const selectSitesLoading = createSelector(
  selectSitesState,
  getSitesLoading,
);

export const selectSiteDetails = createSelector(
  selectSitesState,
  getSiteDetails,
);
export const selectSiteDetailsError = createSelector(
  selectSitesState,
  getSiteDetailsError,
);
export const selectSiteDetailsLoading = createSelector(
  selectSitesState,
  getSiteDetailsLoading,
);

export const selectUpdateServicesLoading = createSelector(
  selectSitesState,
  updateServicesLoading,
);
export const selectUpdateServicesError = createSelector(
  selectSitesState,
  updateServicesError,
);
export const selectUpdateSiteError = createSelector(
  selectSitesState,
  updateSiteError,
);
export const selectUpdateSiteHoursError = createSelector(
  selectSitesState,
  updateSiteHoursError,
);
export const selectUpdateSiteHoursLoading = createSelector(
  selectSitesState,
  updateSiteHoursLoading,
);
export const selectUpdateSiteLoading = createSelector(
  selectSitesState,
  updateSiteLoading,
);
