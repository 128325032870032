import { Action, createReducer, on } from '@ngrx/store';
import {
  clearHistoryOfChangeDetails,
  createSite,
  createSiteFailure,
  createSiteSuccess,
  getClinicServices,
  getClinicServicesFailure,
  getClinicServicesSuccess,
  getGrantIdByCldbSite,
  getGrantIdByCldbSiteFailure,
  getGrantIdByCldbSiteSuccess,
  getHistoryOfChangeDetails,
  getHistoryOfChangeDetailsFailure,
  getHistoryOfChangeDetailsSuccess,
  getHistoryOfChanges,
  getHistoryOfChangesFailure,
  getHistoryOfChangesSuccess,
  getSiteDetails,
  getSiteDetailsFailure,
  getSiteDetailsSuccess,
  getSites,
  getSitesFailure,
  getSitesSuccess,
  publishSite,
  publishSiteFailure,
  publishSiteSuccess,
  removeSite,
  removeSiteFailure,
  removeSiteSuccess,
  resetSiteDetails,
  setNoaFile,
  updateProvidesServicesSuccess,
  updateServices,
  updateServicesFailure,
  updateServicesSuccess,
  updateSite,
  updateSiteFailure,
  updateSiteHours,
  updateSiteHoursFailure,
  updateSiteSuccess,
} from './actions';
import { State, initialState } from './state';

export const siteFeatureKey = 'sites';

const siteReducer = createReducer(
  initialState,

  on(clearHistoryOfChangeDetails, (state) => ({
    ...state,
    historyOfChangeDetails: null,
  })),

  on(createSite, (state) => ({
    ...state,
    createSiteError: null,
    createSiteLoading: true,
  })),
  on(createSiteFailure, (state, { error, message }) => ({
    ...state,
    createSiteError: { error, message },
    createSiteLoading: false,
  })),
  on(createSiteSuccess, (state, { response }) => ({
    ...state,
    createSiteError: null,
    createSiteLoading: false,
    currentSiteId: response.data[0].clinicId,
  })),

  on(getClinicServices, (state) => ({
    ...state,
    getClinicServicesLoading: true,
  })),
  on(getClinicServicesFailure, (state, { error, message }) => ({
    ...state,
    clinicServices: null,
    getClinicServicesFailure: { error, message },
    getClinicServicesLoading: false,
  })),
  on(getClinicServicesSuccess, (state, { clinicServices }) => ({
    ...state,
    clinicServices: clinicServices.data,
    getClinicServicesFailure: null,
    getClinicServicesLoading: false,
  })),

  on(getGrantIdByCldbSite, (state) => ({
    ...state,
    associatedGrantLoading: true,
  })),
  on(getGrantIdByCldbSiteFailure, (state, { error, message }) => ({
    ...state,
    associatedGrant: null,
    associatedGrantError: { error, message },
    associatedGrantLoading: false,
  })),
  on(getGrantIdByCldbSiteSuccess, (state, { associatedGrant }) => ({
    ...state,
    associatedGrant: associatedGrant.data,
    associatedGrantError: null,
    associatedGrantLoading: false,
  })),

  on(getHistoryOfChanges, (state) => ({
    ...state,
    historyOfChangesLoading: true,
  })),
  on(getHistoryOfChangesFailure, (state, { error, message }) => ({
    ...state,
    historyOfChanges: [],
    historyOfChangesError: { error, message },
    historyOfChangesLoading: false,
  })),
  on(getHistoryOfChangesSuccess, (state, { historyOfChanges }) => ({
    ...state,
    historyOfChanges: historyOfChanges.data,
    historyOfChangesLoading: false,
  })),

  on(getHistoryOfChangeDetails, (state) => ({
    ...state,
    historyOfChangeDetailsLoading: true,
  })),
  on(getHistoryOfChangeDetailsFailure, (state, { error, message }) => ({
    ...state,
    historyOfChangeDetails: null,
    historyOfChangeDetailsError: { error, message },
    historyOfChangeDetailsLoading: false,
  })),
  on(getHistoryOfChangeDetailsSuccess, (state, { historyOfChangeDetails }) => ({
    ...state,
    historyOfChangeDetails: historyOfChangeDetails.data as any,
    historyOfChangeDetailsLoading: false,
  })),

  on(getSites, (state) => ({
    ...state,
    sitesLoading: true,
  })),
  on(getSitesFailure, (state, { error, message }) => ({
    ...state,
    sites: [],
    sitesError: { error, message },
    sitesLoading: false,
  })),
  on(getSitesSuccess, (state, { sites }) => ({
    ...state,
    sites: sites.data,
    sitesLoading: false,
  })),

  on(getSiteDetails, (state) => ({
    ...state,
    siteDetailsLoading: true,
  })),
  on(getSiteDetailsFailure, (state, { error, message }) => ({
    ...state,
    siteDetails: null,
    siteDetailsError: { error, message },
    siteDetailsLoading: false,
  })),
  on(getSiteDetailsSuccess, (state, { siteDetails }) => ({
    ...state,
    siteDetails: siteDetails.data,
    siteDetailsLoading: false,
  })),

  on(removeSite, (state) => ({
    ...state,
    removeSiteLoading: true,
  })),
  on(removeSiteFailure, (state, { error, message }) => ({
    ...state,
    removeSiteError: { error, message },
    removeSiteLoading: false,
  })),
  on(removeSiteSuccess, (state) => ({
    ...state,
    removeSiteError: null,
    removeSiteLoading: false,
  })),

  on(resetSiteDetails, (state) => ({
    ...state,
    associatedGrant: {
      grantId: null,
      subrecipientId: null,
    },
    siteDetails: null,
  })),

  on(setNoaFile, (state, { noaFile }) => ({
    ...state,
    noaFile,
  })),

  on(publishSite, (state) => ({
    ...state,
    publishSiteLoading: true,
  })),
  on(publishSiteFailure, (state, { error, message }) => ({
    ...state,
    publishSiteError: { error, message },
    publishSiteLoading: false,
  })),
  on(publishSiteSuccess, (state) => ({
    ...state,
    publishSiteError: null,
    publishSiteLoading: false,
  })),

  on(updateProvidesServicesSuccess, (state, { selection }) => ({
    ...state,
    siteDetails: { ...state.siteDetails, providesServices: selection },
  })),

  on(updateServices, (state) => ({
    ...state,
    updateServicesLoading: true,
  })),
  on(updateServicesSuccess, (state) => ({
    ...state,
    updateServicesLoading: false,
  })),
  on(updateServicesFailure, (state, { error, message }) => ({
    ...state,
    updateServicesLoading: false,
    updateServicesError: { error, message },
  })),

  on(updateSite, (state) => ({
    ...state,
    updateSiteLoading: true,
  })),
  on(updateSiteFailure, (state, { error, message }) => ({
    ...state,
    updateSiteError: { error, message },
    updateSiteLoading: false,
  })),
  on(updateSiteSuccess, (state) => ({
    ...state,
    updateSiteError: null,
    updateSiteLoading: false,
  })),

  on(updateSiteHours, (state) => ({
    ...state,
    updateSiteHoursLoading: true,
  })),
  on(updateSiteHoursFailure, (state, { error, message }) => ({
    ...state,
    updateSiteHoursError: { error, message },
    updateSiteHoursLoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  siteReducer(state, action);
