export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '2.3.2',
    name: 'fpar',
    versionDate: '2025-04-03T18:35:15.568Z',
};
export default versions;
