import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constructFormDataPayload } from '@core/helpers';
import {
  AddSiteApiResponse,
  AddSitePayload,
  AddSiteResponse,
  ClinicServicesApiResponse,
  HistoryOfChangesApiResponse,
  HistoryOfHoursChangeApiResponse,
  HistoryOfOrganizationChangeApiResponse,
  HistoryOfServicesChangeApiResponse,
  RemoveSiteApiResponse,
  RemoveSitePayload,
  RemoveSiteResponse,
  ServicesPayload,
  SiteApiResponse,
  SiteDetailsApiResponse,
  SiteHoursOfOperationPayload,
  SiteLocationPayload,
  UserGrantSelectionApiResponse,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private baseUrl = `${env.apiURL}api/v1`;
  private httpAws: HttpClient;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
  ) {
    this.httpAws = new HttpClient(handler);
  }

  public createSite(
    grantId: number,
    payload: AddSitePayload,
  ): Observable<AddSiteApiResponse> {
    return this.http.post<AddSiteApiResponse>(
      `${this.baseUrl}/grants/${grantId}/sites`,
      { ...payload },
    );
  }

  public getClinicServices(): Observable<ClinicServicesApiResponse> {
    return this.http.get<ClinicServicesApiResponse>(
      `${this.baseUrl}/cldb/services`,
    );
  }

  public getGrantIdByCldbSite(
    siteId: string,
  ): Observable<UserGrantSelectionApiResponse> {
    return this.http.get<UserGrantSelectionApiResponse>(
      `${this.baseUrl}/cldb/${siteId}`,
    );
  }

  public getHistoryOfChanges(
    grantId: number,
    siteId: string,
  ): Observable<HistoryOfChangesApiResponse> {
    return this.http.get<HistoryOfChangesApiResponse>(
      `${this.baseUrl}/grants/${grantId}/sites/${siteId}/history-of-changes`,
    );
  }

  public getHistoryOfChangeById(
    grantId: number,
    siteId: string,
    hocId: number,
    services: boolean,
  ): Observable<
    | HistoryOfHoursChangeApiResponse
    | HistoryOfOrganizationChangeApiResponse
    | HistoryOfServicesChangeApiResponse
  > {
    return this.http.get<
      | HistoryOfHoursChangeApiResponse
      | HistoryOfOrganizationChangeApiResponse
      | HistoryOfServicesChangeApiResponse
    >(
      `${this.baseUrl}/grants/${grantId}/sites/${siteId}/history-of-changes/${services ? 'services/' : ''}${hocId}`,
    );
  }

  public getSites(
    grantId: number,
    subrecipientId?: number,
  ): Observable<SiteApiResponse> {
    return this.http.get<SiteApiResponse>(
      `${this.baseUrl}/grants/${grantId}/sites`,
      { params: { subrecipientId } },
    );
  }

  public getSiteDetails(
    grantId: number,
    siteId: string,
  ): Observable<SiteDetailsApiResponse> {
    return this.http.get<SiteDetailsApiResponse>(
      `${this.baseUrl}/grants/${grantId}/sites/${siteId}`,
    );
  }

  public postNoaFileToS3(
    s3PostConfig: AddSiteResponse | RemoveSiteResponse,
    noaFile: File,
  ): Observable<null[]> {
    const { url, fields } = s3PostConfig.presignedPost;

    return this.httpAws.post<null>(
      url,
      constructFormDataPayload(fields, noaFile),
    );
  }

  public removeSite(
    grantId: number,
    siteId: string,
    payload: RemoveSitePayload,
  ): Observable<RemoveSiteApiResponse> {
    return this.http.put<RemoveSiteApiResponse>(
      `${this.baseUrl}/grants/${grantId}/sites/${siteId}/active`,
      { ...payload },
    );
  }

  public publishSite(
    grantId: number,
    siteId: string,
    published: boolean,
  ): Observable<SiteApiResponse> {
    return this.http.put<SiteApiResponse>(
      `${this.baseUrl}/grants/${grantId}/sites/${siteId}/published`,
      { published },
    );
  }

  public updateServices(
    grantId: number,
    siteId: string,
    payload: ServicesPayload,
  ): Observable<SiteApiResponse> {
    return this.http.put<SiteApiResponse>(
      `${this.baseUrl}/grants/${grantId}/sites/${siteId}/services`,
      { ...payload },
    );
  }

  public updateSite(
    grantId: number,
    siteId: string,
    payload: SiteLocationPayload,
  ): Observable<SiteApiResponse> {
    return this.http.put<SiteApiResponse>(
      `${this.baseUrl}/grants/${grantId}/sites/${siteId}`,
      { ...payload },
    );
  }

  public updateSiteHours(
    grantId: number,
    siteId: string,
    payload: SiteHoursOfOperationPayload,
  ): Observable<SiteApiResponse> {
    return this.http.put<SiteApiResponse>(
      `${this.baseUrl}/grants/${grantId}/sites/${siteId}/hours-of-operation`,
      { ...payload },
    );
  }
}
